<template>
  <div class="m-5">
    <div>
      <el-breadcrumb separator-class="el-icon-arrow-right" class="mb-4">
        <el-breadcrumb-item :to="{ name: 'EditFaqHome' }"
          >Home</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ name: 'EditFaqMain' }"
          >關於我們</el-breadcrumb-item
        >
        <el-breadcrumb-item v-if="$route.name === 'EditFaqCategory'">
          Category ({{ helper.displayI18nText($i18n.locale, getCurrentLabel.label) }})
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="d-flex">
      <div>
        <h2>Category ({{ helper.displayI18nText($i18n.locale, getCurrentLabel.label) }})</h2>
      </div>
      <div class="pl-4" style="padding-top:5px;">
        <el-button
          type="success"
          size="small"
          icon="el-icon-plus"
          @click="dialogCreateVisible = true"
          >新增 Topic</el-button
        >
      </div>
    </div>

    <div>
      <el-table :data="getTopicList.faq_topics" style="width: 100%">
        <el-table-column type="index" width="100"></el-table-column>
        <el-table-column label="Topic" width="400">
          <template slot-scope="scope">
            <div
              style="cursor: pointer;"
              @click="handleRouterChange(scope.row)"
            >
              {{ helper.displayI18nText($i18n.locale, scope.row.title) }}
            </div>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="400">
          <template slot-scope="scope">
            <el-button
              @click="handleEditTopic(scope.row)"
              type="success"
              plain
              size="mini"
            >
              <i class="el-icon-edit"></i>
              更改名稱
            </el-button>
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.row)"
            >
              <i class="el-icon-delete"></i>
              刪除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <br />
    </div>

    <!-- dialog create new topic -->
    <el-dialog
      title="Create New Topic"
      :visible.sync="dialogCreateVisible"
      width="60%"
      :close-on-click-modal="false"
      :before-close="handleCancelCreateTopic"
    >
      <el-form>
        <el-tabs type="border-card" v-model="defaultTabCreate">
          <el-tab-pane label="Topic (EN)" name="1">
            <el-form-item label="Topic (EN)">
              <el-input v-model="newTopic.title.en"></el-input>
            </el-form-item>
          </el-tab-pane>
          <el-tab-pane label="Topic (TW)" name="2">
            <el-form-item label="Topic (TW)">
              <el-input v-model="newTopic.title.tw"></el-input>
            </el-form-item>
          </el-tab-pane>
          <el-tab-pane label="Topic (CN)" name="3">
            <el-form-item label="Topic (CN)">
              <el-input v-model="newTopic.title.cn"></el-input>
            </el-form-item>
          </el-tab-pane>
        </el-tabs>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCancelCreateTopic">Cancel</el-button>
        <el-button type="primary" @click="submitCreateTopic">Submit</el-button>
      </span>
    </el-dialog>

    <!-- dialog edit current topic -->
    <el-dialog
      title="Edit Topic"
      :visible.sync="dialogEditVisible"
      width="60%"
      :close-on-click-modal="false"
      :before-close="handleCancelEditTopic"
    >
      <el-form>
        <el-tabs type="border-card" v-model="defaultTabEdit">
          <el-tab-pane label="Topic (EN)" name="1">
            <el-form-item label="Topic (EN)">
              <el-input v-model="currentSelectedTopic.title.en"></el-input>
            </el-form-item>
          </el-tab-pane>
          <el-tab-pane label="Topic (TW)" name="2">
            <el-form-item label="Topic (TW)">
              <el-input v-model="currentSelectedTopic.title.tw"></el-input>
            </el-form-item>
          </el-tab-pane>
          <el-tab-pane label="Topic (CN)" name="3">
            <el-form-item label="Topic (CN)">
              <el-input v-model="currentSelectedTopic.title.cn"></el-input>
            </el-form-item>
          </el-tab-pane>
        </el-tabs>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCancelEditTopic">Cancel</el-button>
        <el-button type="primary" @click="submitUpdateTopic">Submit</el-button>
      </span>
    </el-dialog>

    <!-- dialog delete current topic -->
    <el-dialog
      title="Delete Topic"
      :visible.sync="dialogDeleteVisible"
      width="60%"
      :close-on-click-modal="false"
      :before-close="handleCancelDeleteTopic"
    >
      <div>是否要刪除此 Topic?</div>
      <div>en: {{ currentSelectedTopic.title.en }}</div>
      <div>tw: {{ currentSelectedTopic.title.tw }}</div>
      <div>cn: {{ currentSelectedTopic.title.cn }}</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCancelDeleteTopic">Cancel</el-button>
        <el-button type="danger" @click="submitDeleteTopic">Delete</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapGetters } from "vuex";
/*eslint-enable */
import { helper } from "@ivy-way/material";

import _ from "lodash";

export default {
  data() {
    return {
      currentSelectedTopic: {},
      dialogCreateVisible: false,
      dialogEditVisible: false,
      dialogDeleteVisible: false,
      newTopic: {},
      defaultTabCreate: "1",
      defaultTabEdit: "1"
    };
  },
  computed: {
    ...mapGetters("faq", ["getCurrentLabel", "getTopicList"]),
    helper() {
      return helper;
    }
  },
  created() {
    this.clearNewTopic();
    this.clearCurrentSelectedTopic();
    this.$store.dispatch("faq/clearFaqList");
  },
  mounted() {
    this.$store.dispatch("faq/getTopicList", this.getCurrentLabel.id);
  },
  methods: {
    // 清空 newTopic
    clearNewTopic() {
      this.newTopic = {
        faq_label_id: this.getCurrentLabel.id,
        title: {
          en: "",
          tw: "",
          cn: ""
        }
      };
    },
    // 清空 currentSelectedTopic
    clearCurrentSelectedTopic() {
      this.currentSelectedTopic = {
        faq_label_id: this.getCurrentLabel.id,
        title: {
          en: "",
          tw: "",
          cn: ""
        }
      };
    },
    handleRouterChange(topic) {
      this.$store.dispatch("faq/saveCurrentTopic", topic);
      this.$router.push({
        name: "EditFaqTopic"
      });
    },

    // create new topic
    // 點選 "取消"
    handleCancelCreateTopic() {
      this.dialogCreateVisible = false;
      this.clearNewTopic();
      this.defaultTabCreate = "1";
    },
    // 點選 "送出"
    async submitCreateTopic() {
      this.dialogCreateVisible = false;
      await this.$store.dispatch("faq/createTopic", this.newTopic);
      this.clearNewTopic();
      await this.$store.dispatch("faq/getTopicList", this.getCurrentLabel.id);
      this.defaultTabCreate = "1";
    },

    // edit topic
    // 在 table 點選某項 topic 時，assign 此 topic 至 currentSelectedTopic
    handleEditTopic(topic) {
      this.currentSelectedTopic = _.cloneDeep(topic);
      this.dialogEditVisible = true;
    },
    // 點選 "取消"
    handleCancelEditTopic() {
      this.dialogEditVisible = false;
      this.clearCurrentSelectedTopic();
      this.defaultTabEdit = "1";
    },
    // 點選 "送出"
    async submitUpdateTopic() {
      await this.$store.dispatch("faq/updateTopic", this.currentSelectedTopic);
      this.clearCurrentSelectedTopic();
      this.dialogEditVisible = false;
      await this.$store.dispatch("faq/getTopicList", this.getCurrentLabel.id);
      this.defaultTabEdit = "1";
    },
    // delete topic
    handleDelete(topic) {
      this.currentSelectedTopic = _.cloneDeep(topic);
      this.dialogDeleteVisible = true;
    },
    handleCancelDeleteTopic() {
      this.dialogDeleteVisible = false;
      this.clearCurrentSelectedTopic();
    },
    async submitDeleteTopic() {
      await this.$store.dispatch(
        "faq/deleteTopic",
        this.currentSelectedTopic.id
      );
      this.clearCurrentSelectedTopic();
      this.dialogDeleteVisible = false;
      await this.$store.dispatch("faq/getTopicList", this.getCurrentLabel.id);
    }
  }
};
</script>

<style lang="scss" scoped>
.el-button {
  outline: none;
}
</style>
